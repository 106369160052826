export const weightOptions = [
  {
    key: 'g',
    label: 'g',
  },
  {
    key: 'kg',
    label: 'kg',
  },
  {
    key: 'oz',
    label: 'oz',
  },
  {
    key: 'lb',
    label: 'lb',
  },
];

export const distanceUnitOptions = [
  {
    key: 'cm',
    label: 'cm',
  },
  {
    key: 'in',
    label: 'in',
  },
  {
    key: 'ft',
    label: 'ft',
  },
  {
    key: 'm',
    label: 'm',
  },
  {
    key: 'mm',
    label: 'mm',
  },
  {
    key: 'yd',
    label: 'yd',
  },
];
