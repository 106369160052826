import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import IconPrevArrow from '../IconPrevArrow/IconPrevArrow';
import IconNextArrow from '../IconNextArrow/IconNextArrow';
import { GENERAL_SEO } from '../../util/enums';
import { isArrayLength } from '../../util/dataExtractors';
import { Instagram } from 'react-content-loader';
import NamedLink from '../NamedLink/NamedLink';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';
import css from './ImageSlider.module.css';

function ImageSlider(props) {
  const {
    images,
    isMobile,
    pushToListingPage,
    isSectionHero = false,
    imgAlt = GENERAL_SEO,
    pathParams = {},
  } = props;

  const handleDragStart = e => e.preventDefault();

  const location = useLocation();

  let items =
    images &&
    images.map((item, index) => (
      <img
        key={index}
        src={item}
        alt={imgAlt}
        onDragStart={handleDragStart}
        role="presentation"
        className={classNames({
          [css.images]: !isSectionHero,
          [css.heroImages]: isSectionHero,
        })}
        onClick={() => pushToListingPage()}
      />
    ));

  const isLandingPage = location && location.pathname === '/';

  // State to keep track of whether component is mounted
  const [isMounted, setIsMounted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // Cancel subscriptions and asynchronous tasks when component is unmounted
  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const settings = {
    dots: isSectionHero ? false : true,
    fade: true,
    arrows: isHovered,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: isSectionHero || (isMobile && isLandingPage),
    speed: 800,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    // nextArrow: <RenderNextArrow />,
    // prevArrow: <RenderPrevArrow />,
  };

  if (isMobile) {
    settings.arrows = false;
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={css.carouselContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMounted && (
        <Slider className={css.cardSlider} {...settings}>
          {isArrayLength(items) ? items : <Instagram />}
        </Slider>
      )}
    </div>
  );
}

export default ImageSlider;
