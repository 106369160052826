import React from 'react';
import Select from 'react-select';
import css from './FieldMultiSelect.module.css';
import { IconSpinner } from '..';

function FieldMultiSelect(props) {
  const {
    input,
    options,
    label,
    isMulti = true,
    disabled = false,
    inProgress,
    onChange,
    customStyles,
  } = props;
  const renderSpinner = inProgress ? (
    <span className={css.loader}>
      <IconSpinner />
    </span>
  ) : null;

  // Handler for change events from the Select component
  const handleChange = selectedOptions => {
    // Call the input.onChange method which updates the form state
    input.onChange(selectedOptions);

    // Call the custom onChange handler if it exists, passing the new value(s)
    if (onChange) onChange(selectedOptions);
  };

  const value = isMulti
    ? input.value || []
    : options.find(option => option.value === input.value) || null;

  return (
    <div className={css.reactSelect}>
      {label && (
        <label>
          {label} {renderSpinner}
        </label>
      )}
      <Select
        defaultValue={value}
        onChange={handleChange}
        options={options}
        isMulti={isMulti}
        styles={customStyles}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
}

const customStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      border: `solid 1px #ddd`,
      boxShadow: 'none',
      borderRadius: '6px',
      padding: '0',
      margin: '0',
      Height: '46px',
      minHeight: '46px',
      '&:hover': {
        border: isFocused ? `solid 1px #e5e5e6` : `solid 1px #ddd`,
      },
    };
  },
  placeholder: styles => ({
    ...styles,
    position: 'relative',
    bottom: '0px',
    padding: '0',
    margin: '0',
  }),
  valueContainer: styles => ({
    ...styles,
    maxHeight: '46px',
    minHeight: '46px',
    padding: '0 10px',
    margin: '0',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#ddd' : '#fff',
      color: isDisabled ? '#ccc' : '#313135',
      fontSize: '15px',
      lineHeight: '100%',
      padding: '10px 16px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      '&:hover': {
        backgroundColor: '#eee',
        color: '#000',
        cursor: 'pointer',
      },
    };
  },
  Input: styles => ({ ...styles, margin: '0px', padding: '0px', maxHeight: 45 }),
  menu: styles => ({
    ...styles,
    backgroundColor: '#fff',
  }),
};

FieldMultiSelect.defaultProps = {
  customStyles,
};

export default FieldMultiSelect;
