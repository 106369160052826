import { ADD } from '../../constants';
import { getUserCartDetails } from '../../util/dataExtractor';
import { isArrayLength, isWindowDefined, showToaster } from '../../util/genericHelpers';
import { addItemToCart, deleteItemFromCart } from './CartPage.duck';

export const extractListingDataForCart = listing => {
  const currentStock = listing && listing.currentStock;
  const quantity = currentStock?.attributes?.quantity;

  const price = listing?.attributes?.price;

  const currency = price?.currency;

  const firstImage = isArrayLength(listing.images) ? listing.images[0] : null;

  const sharetribeImage =
    firstImage &&
    firstImage.attributes &&
    firstImage.attributes.variants &&
    firstImage.attributes.variants['listing-card-2x'] &&
    firstImage.attributes.variants['listing-card-2x'].url;

  const listingId = listing?.id?.uuid;
  const title = listing?.attributes?.title;
  const authorId = listing?.author?.id?.uuid;
  const publicData = listing?.attributes?.publicData;
  const shippingPriceInSubunitsOneItem = publicData?.shippingPriceInSubunitsOneItem;
  const shippingPriceInSubunitsAdditionalItems = publicData?.shippingPriceInSubunitsAdditionalItems;

  return {
    listingId,
    stockCount: 1,
    price,
    title,
    authorId,
    shippingPriceInSubunitsOneItem,
    shippingPriceInSubunitsAdditionalItems,
    currency,
    oldStock: quantity,
    listingImage: sharetribeImage,
  };
};

export const onToggleQuanity = params => {
  const {
    currentItem,
    currentCartItem,
    setCurrentItem,
    setStockDetails,
    stockDetails,
    type,
  } = params;
  const currentItemParams = {
    ...currentItem,
    stockCount: type === ADD ? currentItem?.stockCount + 1 : currentItem?.stockCount - 1,
  };

  stockDetails[currentCartItem.index] = currentItemParams;
  setCurrentItem(currentItemParams);
  setStockDetails(stockDetails);
};

export const removeItemFromCart = async params => {
  const { dispatch, ...rest } = params;
  try {
    const response = await dispatch(deleteItemFromCart(rest));
  } catch (error) {
    showToaster('error', 'Error removing item from cart');
  }
};

export const getLocalCartItems = () => {
  return JSON.parse(isWindowDefined() && window.localStorage.getItem('localCartItems')) || [];
};
export const setLocalCartItems = data => {
  return isWindowDefined() && window.localStorage.setItem('localCartItems', JSON.stringify(data));
};
export const removeLocalCartItems = () => {
  return isWindowDefined() && window.localStorage.removeItem('localCartItems');
};

export const getAllCartItems = currentUser => {
  const cartItems = getUserCartDetails(currentUser);
  const localCartItems = getLocalCartItems();

  // Combine cart items from user data and local storage
  const allCartItems = [...(cartItems || []), ...(localCartItems || [])];

  // Filter out duplicates (assuming cart items have a unique identifier)
  const uniqueCartItems = new Set(allCartItems.map(item => item)); // Or any unique identifier property

  // Convert unique identifiers back to cart items
  const combinedCartItems = Array.from(uniqueCartItems).map(id => {
    // Find the corresponding item in the combined list
    const matchingItem = allCartItems.find(item => item === id);
    return matchingItem;
  });

  // Return the combined and deduplicated cart items
  return combinedCartItems;
};

export const cartItemsCheckoutData = params => {
  try {
  } catch (error) {}
};

export const handleAddToCart = async params => {
  const { dispatch, id, cartItems } = params || {};
  try {
    await dispatch(addItemToCart({ id, cartItems }));
  } catch (error) {
    showToaster('error', 'Unable to add to cart');
  }
};
