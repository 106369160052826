import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import {
  updateProfileError,
  updateProfileRequest,
  updateProfileSuccess,
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ShoppingPage/CLEAR_UPDATED_FORM';

export const UPDATE_PROFILE_REQUEST = 'app/ShoppingPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ShoppingPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ShoppingPage/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id

export const updateShoppingPreferences = actionPayload => {
  return async (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    try {
      const response = await dispatch(updateProfile(actionPayload, queryParams));
      dispatch(updateProfileSuccess(response));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      return response;
    } catch (e) {
      dispatch(updateProfileError(storableError(e)));
    }
  };
};
