import { useCallback, useRef, useState } from 'react';
import { isBookingProcessAlias } from '../transactions/transaction';
import { toast } from 'react-toastify';
import { getUserCartDetails } from './dataExtractor';

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export const setNoAvailabilityForUnbookableListings = processAlias => {
  return isBookingProcessAlias(processAlias)
    ? {}
    : {
        availabilityPlan: {
          type: 'availability-plan/time',
          timezone: 'Etc/UTC',
          entries: [
            // Note: "no entries" is the same as seats=0 for every entry.
            // { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 0 },
            // { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 0 },
          ],
        },
      };
};

export function capitalizeFirstLetter(str) {
  if (!str) return null;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const sortCountriesWithPriority = countriesArray => {
  // Priority list of country codes
  const priorityCodes = ['US', 'GB'];

  // Splitting the array into priority and normal countries
  const priorityCountries = countriesArray.filter(country => priorityCodes.includes(country.code));
  const otherCountries = countriesArray.filter(country => !priorityCodes.includes(country.code));

  // Optional: Sort otherCountries alphabetically by label if needed
  otherCountries.sort((a, b) => a.label?.localeCompare(b.label));

  // Concatenating the priority countries with the sorted other countries
  return [...priorityCountries, ...otherCountries];
};

export function removeSpecialCharacters(text) {
  // Define the regular expression pattern to match any character that is not alphanumeric or whitespace
  var pattern = /[^A-Z0-9]/gi;
  // Use the replace() method to replace matched patterns with an empty string
  var cleanedText = text.replace(pattern, '');
  return cleanedText;
}

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options, // Merge any additional options passed to the function
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, defaultOptions);
  } else if (type === 'error') {
    toast.error(message, defaultOptions);
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
  }
};

export const groupListingsByAuthor = listings => {
  return listings.reduce((grouped, listing) => {
    const authorId = listing.author.id.uuid;
    if (!grouped[authorId]) {
      grouped[authorId] = [];
    }
    grouped[authorId].push(listing);
    return grouped;
  }, {});
};

export const cardRenderSizes = isMapVariant => {
  if (isMapVariant) {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  } else {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  }
};
export const removeUnits = label => {
  return label.replace(/Units/gi, '').trim();
};

// Function to add days to the current date
export const addDaysToDate = days => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const formatServiceLevel = serviceLevel => {
  return serviceLevel
    .split('_') // Split the string into an array using underscores as separators
    .map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first character of each word
    )
    .join(' '); // Join the array back into a single string with spaces
};
