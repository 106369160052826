import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { getUserCartDetails } from '../../util/dataExtractor';
import { isArrayLength } from '../../util/genericHelpers';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getLocalCartItems, setLocalCartItems } from './cartHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const RESULT_PAGE_SIZE = 50;

// ================ Action types ================ //

export const FETCH_LISTING_REQUEST = 'app/CartPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/CartPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/CartPage/FETCH_LISTING_ERROR';

export const ADD_TO_CART = 'app/CartPage/ADD_TO_CART';
export const REMOVE_ITEM_FROM_CART_REQUEST = 'app/CartPage/REMOVE_ITEM_FROM_CART_REQUEST';
export const UNSET_REMOVING_ITEM_IN_PROGRESS = 'app/CartPage/UNSET_REMOVING_ITEM_IN_PROGRESS';
export const REMOVE_ITEM_FROM_CART_SUCCESS = 'app/CartPage/REMOVE_ITEM_FROM_CART_SUCCESS';
export const REMOVE_ITEM_FROM_CART_ERROR = 'app/CartPage/REMOVE_ITEM_FROM_CART_ERROR';
export const UPDATE_CURRENT_PAGE_RESULT_IDS = 'app/CartPage/UPDATE_CURRENT_PAGE_RESULT_IDS';
export const UPDATE_CART_COUNT = 'app/CartPage/UPDATE_CART_COUNT';

// ================ Reducer ================ //

const initialState = {
  cart: [],
  cartCount: 0,
  fetchListingInProgress: false,
  fetchListingError: null,
  currentPageResultIds: [],
  removeItemFromCartInProgress: [],
  removeItemFromCartError: null,
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_CART:
      // Check if the item is already in the cart
      if (!state.cart.includes(payload)) {
        return {
          ...state,
          cart: [...state.cart, payload],
        };
      } else {
        return state; // Return current state if item is already in cart
      }
    case UPDATE_CART_COUNT:
      return {
        ...state,
        cartCount: payload,
      };

    case FETCH_LISTING_REQUEST:
      return {
        ...state,
        fetchListingInProgress: true,
        currentPageResultIds: [],
        fetchListingError: null,
      };
    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchListingInProgress: false,
      };
    case UPDATE_CURRENT_PAGE_RESULT_IDS:
      return {
        ...state,
        currentPageResultIds: payload,
      };
    case FETCH_LISTING_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, fetchListingInProgress: false, fetchListingError: payload };
    case REMOVE_ITEM_FROM_CART_REQUEST:
      console.log(payload, 'load');
      return {
        ...state,
        removeItemFromCartInProgress: [...state.removeItemFromCartInProgress, payload],
        removeItemFromCartError: null,
      };
    case UNSET_REMOVING_ITEM_IN_PROGRESS:
      return {
        ...state,
        removeItemFromCartInProgress: state.removeItemFromCartInProgress.filter(
          itemId => itemId !== payload
        ),
        removeItemFromCartError: null,
      };

    case REMOVE_ITEM_FROM_CART_SUCCESS:
      return {
        ...state,
        removeItemFromCartInProgress: [],
        removeItemFromCartError: null,
      };
    case REMOVE_ITEM_FROM_CART_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, removeItemFromCartInProgress: [], removeItemFromCartError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const cartSelector = state => {
  const { cart, cartCount, removeItemFromCartInProgress, removeItemFromCartError } = state.CartPage;
  return {
    cart,
    cartCount,
    removeItemFromCartInProgress,
    removeItemFromCartError,
  };
};

// ================ Action creators ================ //

export const addToCart = data => ({ type: ADD_TO_CART, payload: data });
export const cartCount = data => ({ type: UPDATE_CART_COUNT, payload: data });

export const fetchListingRequest = () => ({
  type: FETCH_LISTING_REQUEST,
});

export const fetchListingSuccess = response => ({
  type: FETCH_LISTING_SUCCESS,
  payload: { data: response.data },
});
export const updateCurrentPageResultIds = response => ({
  type: UPDATE_CURRENT_PAGE_RESULT_IDS,
  payload: response,
});

export const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});
export const removeItemFromCartRequest = id => ({
  type: REMOVE_ITEM_FROM_CART_REQUEST,
  payload: id,
});
export const unsetRemovingItemInProgress = id => ({
  type: UNSET_REMOVING_ITEM_IN_PROGRESS,
  payload: id,
});

export const removeItemFromCartSuccess = () => ({
  type: REMOVE_ITEM_FROM_CART_SUCCESS,
});

export const removeItemFromCartError = e => ({
  type: REMOVE_ITEM_FROM_CART_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const addItemToCart = params => async (dispatch, getState, sdk) => {
  try {
    const { id, cartItems } = params;
    const { isAuthenticated } = getState().auth;
    dispatch(addToCart(id));
    dispatch(updateCartCount());
    if (isAuthenticated) {
      if (!cartItems.includes(id)) {
        const updatedCartItems = [...cartItems, id];
        await dispatch(
          updateProfile({
            protectedData: {
              cartItems: updatedCartItems,
            },
          })
        );
      }
    } else {
      const localCartItems = getLocalCartItems();
      if (!localCartItems.includes(id)) {
        const updatedCartItems = [...localCartItems, id];
        setLocalCartItems(updatedCartItems);
      }
    }
  } catch (error) {}
};
export const updateCartCount = params => (dispatch, getState, sdk) => {
  try {
    const { cart } = getState().CartPage;
    dispatch(cartCount(cart.length));
  } catch (error) {}
};
export const deleteItemFromCart = params => async (dispatch, getState, sdk) => {
  const { cartItems, id, isAuthenticated, config } = params;
  dispatch(removeItemFromCartRequest(id));
  try {
    const { currentPageResultIds } = getState().CartPage;
    // Update currentPageResultIds locally before server update
    const updatedCurrentPageResultIds = currentPageResultIds.filter(item => item.uuid !== id);
    if (isAuthenticated && isArrayLength(cartItems)) {
      const updatedCartItems = cartItems.filter(item => item !== id);
      await dispatch(
        updateProfile({
          protectedData: {
            cartItems: updatedCartItems,
          },
        })
      );
    } else {
      const localCartItems = getLocalCartItems();
      const updatedLocalCartItems = localCartItems.filter(item => item !== id);
      setLocalCartItems(updatedLocalCartItems);
    }

    // dispatch(cartCount(cart.length));
    dispatch(updateCurrentPageResultIds(updatedCurrentPageResultIds));
    dispatch(removeItemFromCartSuccess());
  } catch (error) {
    dispatch(removeItemFromCartError(storableError(error)));
  } finally {
    dispatch(unsetRemovingItemInProgress(id)); // Unset the item as in progress
  }
};

export const fetchListings = (params, config) => async (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());
  try {
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;

    const queryParams = {
      ids: params,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };
    const response = await sdk.listings.query(queryParams);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingSuccess(response));
    return response;
  } catch (error) {
    dispatch(fetchListingError(storableError(error)));
  }
};
export const loadData = (params, search, config) => async dispatch => {
  try {
    const currentUser = await dispatch(fetchCurrentUser());
    const cartItems = getUserCartDetails(currentUser) || getLocalCartItems();
    // Flatten the cart items if they're nested arrays (assuming correct data structure)
    if (isArrayLength(cartItems)) {
      dispatch(addToCart(cartItems));
      dispatch(fetchListings(cartItems, config));
    }
  } catch (error) {}
};
