//Listing categories
export const CATEGORY = 'category';
export const COSTUMES = 'costumes';
export const SKATES = 'skates-blades';
export const BLADES = 'blades';
export const BOOTS = 'boots';
export const PRACTICE_WEAR = 'practice-wear';
export const ACCESSORIES = 'accessories';
export const DISABLED_ADD_ICON = 'disabledAddIcon';
export const ADD_ICON = 'addIcon';
export const ADD = 'add';
export const SUBTRACT = 'subtract';
export const DISABLED_SUBTRACT_ICON = 'disabledSubtractIcon';
export const PRICE = 'price';
export const SIZE = 'size';
export const COLOR = 'color';
export const CONDITION = 'condition';
export const KEYWORDS = 'keywords';

//Shipping options
export const PLATFORM = 'platform';
export const OWN = 'own';

export const SHIPPING_PREFERENCE = 'shippingPreference';

export const DEFAULT_RETURN_POLICY_DAYS = '7';
