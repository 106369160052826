import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { isMobile } from 'react-device-detect';
import {
  AspectRatioWrapper,
  AvatarSmall,
  IconBag,
  IconBagFilled,
  Icons,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../ducks/auth.duck';
import { addItemToCart, cartSelector } from '../../containers/CartPage/CartPage.duck';
import { getUserCartDetails } from '../../util/dataExtractor';
import { selectCurrentUser } from '../../ducks/user.duck';
import { isArrayLength, showToaster } from '../../util/genericHelpers';
import { removeItemFromCart } from '../../containers/CartPage/cartHelpers';
import {
  getProductImages,
  getRecentlyViewed,
  getStoreName,
  getUsername,
} from '../../util/dataExtractors';
import ImageSlider from '../ImageSlider/ImageSlider';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isListView,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const cartItems = getUserCartDetails(currentUser);
  const recentlyViewed = getRecentlyViewed(currentUser) || [];

  const classes = classNames(
    rootClassName || css.root,
    className,
    isListView ? css.listViewCard : null
  );
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;

  const isAlreadyAddedToCart = isArrayLength(cartItems) && !!cartItems.find(item => item === id);

  const { title = '', price, publicData, description } = currentListing.attributes || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const isOwnListing = author && author?.id?.uuid === currentUser?.id?.uuid;

  const authorName = author.attributes.profile.displayName;

  const storeName = getStoreName(author);
  const username = getUsername(author);
  const authorDisplayName = author && userDisplayNameAsString(author, '');
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];
  const images = firstPhoto ? photos : getProductImages(currentListing.images);
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const handleAddToCart = async id => {
    try {
      await dispatch(addItemToCart({ id, cartItems }));
    } catch (error) {
      showToaster('error', 'Unable to add to cart');
    }
  };
  const onUpdateRecentlyViewed = () => {
    if (isAuthenticated) {
      recentlyViewed.push(id);
      const viewed = [...new Set(recentlyViewed)];
      Array.isArray(viewed) && viewed.reverse();
      const slicedViews = isArrayLength(viewed) ? viewed.slice(0, 11) : viewed;
      dispatch(
        updateProfile({
          publicData: {
            recentlyViewed: slicedViews,
          },
        })
      );
    }
  };
  const display = storeName ? storeName : authorDisplayName;
  const imgAlt = title + ' ' + (display || '') + (' ' + (username || ''));
  return (
    <>
      {isListView ? (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
        </NamedLink>
      ) : (
        <div
          className={classes}
          onClick={() =>
            typeof window !== 'undefined' && window.sessionStorage.setItem('saveScroll', 'true')
          }
        >
          <div className={css.cardImg}>
            {!isOwnListing && (
              <span
                className={css.cartIcon}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (isAlreadyAddedToCart) {
                    removeItemFromCart({
                      isAuthenticated,
                      id,
                      dispatch,
                      cartItems,
                      config,
                    });
                  } else {
                    handleAddToCart(id);
                  }
                }}
              >
                {isAlreadyAddedToCart ? <IconBagFilled /> : <IconBag />}
              </span>
            )}
            {images && images.length ? (
              <ImageSlider
                images={images}
                isMobile={isMobile}
                imgAlt={imgAlt}
                pathParams={{ id, slug }}
                pushToListingPage={() => {
                  history.push(
                    createResourceLocatorString(
                      'ListingPage',
                      routeConfiguration(),
                      { id, slug },
                      {}
                    )
                  );
                  onUpdateRecentlyViewed();
                }}
              />
            ) : null}{' '}
          </div>
          {/* <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
              {!isOwnListing && (
                <span
                  className={css.cartIcon}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (isAlreadyAddedToCart) {
                      removeItemFromCart({
                        isAuthenticated,
                        id,
                        dispatch,
                        cartItems,
                        config,
                      });
                    } else {
                      handleAddToCart(id);
                    }
                  }}
                >
                  {isAlreadyAddedToCart ? <IconBagFilled /> : <IconBag />}
                </span>
              )}
            </AspectRatioWrapper>
          </NamedLink> */}
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            </div>
            <p>
              {description
                ? description
                : 'Doodle characters doing some online shopping! An illustration system based on all things eCommerce'}
            </p>
            <div className={css.authorSec}>
              {showAuthorInfo ? (
                <div className={css.authorInfo}>
                  <AvatarSmall className={css.avatar} user={author} />
                  <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                </div>
              ) : null}{' '}
              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
