import { OWN } from '../constants';
import { ShippoApi } from '../util/api';
import { isArrayLength } from '../util/genericHelpers';
import { fetchCurrentUser } from './user.duck';

// ================ Action types ================ //

export const FETCH_LISTINGS_IN_PROGRESS = 'app/shippo/FETCH_LISTINGS_IN_PROGRESS';
export const FETCH_LISTINGS_SUCCESS = 'app/shippo/FETCH_LISTINGS_SUCCESS';

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/shippo/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/shippo/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/shippo/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/shippo/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/shippo/SAVE_CONTACT_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/shippo/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/shippo/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/shippo/RESET_PASSWORD_ERROR';

export const CREATE_SHIPPO_ADDRESS_REQUEST = 'app/shippo/CREATE_SHIPPO_ADDRESS_REQUEST';
export const CREATE_SHIPPO_ADDRESS_SUCCESS = 'app/shippo/CREATE_SHIPPO_ADDRESS_SUCCESS';
export const CREATE_SHIPPO_ADDRESS_ERROR = 'app/shippo/CREATE_SHIPPO_ADDRESS_ERROR';

export const SAVE_LISTINGS_RESPONSE = 'app/shippo/SAVE_LISTINGS_RESPONSE';
export const SAVE_WEIGHT_IN_PROGRESS = 'app/shippo/SAVE_WEIGHT_IN_PROGRESS';

export const CLEAR_FORM = 'app/shippo/CLEAR_FORM';

// ================ Reducer ================ //

const initialState = {
  saveShippoAddressInProgress: false,
  shippoAddressSaved: false,
  saveShippoAddressError: null,
  clearForm: false,
  saveWeightInProgress: false,
  fetchListingsInProgress: false,
  listingsFetched: false,
  saveWeightInProgress: false,
  allListings: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_IN_PROGRESS:
      return {
        ...state,
        fetchListingsInProgress: true,
      };
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, listingsFetched: false, fetchListingsInProgress: false };
    case CREATE_SHIPPO_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippoAddressInProgress: true,
      };
    case CREATE_SHIPPO_ADDRESS_SUCCESS:
      return { ...state, saveShippoAddressInProgress: false, shippoAddressSaved: true };
    case CREATE_SHIPPO_ADDRESS_ERROR:
      return {
        ...state,
        saveShippoAddressInProgress: false,
        saveShippoAddressError: true,
      };
    case SAVE_LISTINGS_RESPONSE:
      return { ...state, allListings: payload.data };
    case CLEAR_FORM:
      return { ...state, clearForm: true };
    case SAVE_WEIGHT_IN_PROGRESS:
      return { ...state, saveWeightInProgress: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchListingsInProgress = () => ({ type: FETCH_LISTINGS_IN_PROGRESS });
export const fetchListingsSuccess = () => ({ type: FETCH_LISTINGS_SUCCESS });

export const createShippoAddressRequest = () => ({ type: CREATE_SHIPPO_ADDRESS_REQUEST });
export const createShippoAddressSuccess = () => ({ type: CREATE_SHIPPO_ADDRESS_SUCCESS });
export const createShippoAddressError = () => ({ type: CREATE_SHIPPO_ADDRESS_ERROR });
export const clearForm = () => ({ type: CLEAR_FORM });
export const saveWeightInProgress = () => ({ type: SAVE_WEIGHT_IN_PROGRESS });

export const saveListingsResponse = response => ({
  type: SAVE_LISTINGS_RESPONSE,
  payload: { data: response },
});

// ================ Selectors ================ //
export const selectShippoAddressStatus = state => {
  return {
    saveShippoAddressInProgress: state?.shippo?.saveShippoAddressInProgress ?? false,
    saveShippoAddressError: state?.shippo?.saveShippoAddressError ?? null,
  };
};
// ================ Thunks ================ //

export const createShippoAddress = params => async (dispatch, getState, sdk) => {
  dispatch(createShippoAddressRequest());
  try {
    const address = Object.keys(params).length && (await ShippoApi.createShippoAddress(params));
    dispatch(createShippoAddressSuccess());
    return address;
  } catch (error) {
    dispatch(createShippoAddressError());
  }
};

export const updateExistingListings = shippingPreference => async (dispatch, getState, sdk) => {
  try {
    const {
      data: { data: listings },
    } = (await sdk.ownListings.query({})) || {};

    if (!isArrayLength(listings)) {
      console.log('No listings to update.');
      return;
    }

    // Determine the common update parameters
    const updateParams =
      shippingPreference === OWN
        ? {
            pickupEnabled: false,
            shippingEnabled: false,
            shippingPriceInSubunitsOneItem: null,
            shippingPriceInSubunitsAdditionalItems: null,
            freeShipping: true,
          }
        : {
            pickupEnabled: false,
            shippingEnabled: true,
            shippingPriceInSubunitsOneItem: null,
            shippingPriceInSubunitsAdditionalItems: null,
            freeShipping: false,
          };

    // Use Promise.all to run all updates in parallel
    await Promise.all(
      listings.map(listing =>
        sdk.ownListings
          .update({
            id: listing.id,
            publicData: updateParams,
          })
          .then(res => console.log(res, `Updated listing: ${listing?.id?.uuid}`))
      )
    );
  } catch (error) {
    console.error('Error updating listings:', error);
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
