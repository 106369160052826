import moment from 'moment';
import { ensureUser } from './data';
import { FREE_SHIPPING, IS_COSTUMES_RENTALS, NO_IMAGE, OFFER_PROCESS, SHIPPING } from './enums';

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Returns the title of the listing associated with a transaction.
 * @param {object} tx - The transaction object.
 * @returns {string|null} The listing title, or null if input is invalid or listing title is missing.
 */
export const getTxTitle = tx => {
  const currentListing = tx?.listing;
  const title = currentListing && currentListing.attributes && currentListing.attributes.title;
  return title;
};

export const getTxListingSKU = tx => {
  const currentListing = tx?.listing;
  const publicData = currentListing?.attributes?.publicData;
  return publicData?.sku || '';
};

/**
 * Returns the number of items in a transaction's cart.
 * @param {object} tx - The transaction object.
 * @returns {number} The number of items in the cart, or 0 if input is invalid or cart is empty.
 */
export const getCartItems = tx => {
  return (tx && tx.id && tx?.attributes?.protectedData?.cartItems) || 0;
};

/**
 * Returns the URL of the image associated with a transaction's listing.
 * @param {object} tx - The transaction object.
 * @returns {string} The URL of the image, or a default image URL if input is invalid or image is missing.
 */
export const getTxImage = tx => {
  const currentListing = tx?.listing;
  const publicData =
    currentListing && currentListing.attributes && currentListing.attributes.publicData;
  const photos = publicData && publicData.photos;
  const firstImage =
    currentListing && currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const awsPhoto = photos && photos.length && photos[0];
  const sharetribeImage =
    firstImage &&
    firstImage.attributes &&
    firstImage.attributes.variants &&
    firstImage.attributes.variants['square-small2x'] &&
    firstImage.attributes.variants['square-small2x'].url;

  return awsPhoto || sharetribeImage || NO_IMAGE;
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

/**
 * Returns an array of bookmarked items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of bookmarked items, or an empty array if input is invalid or bookmarks are missing.
 */
export const getBookmarks = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.bookmarks) || []
  );
};

/**
 * Returns an array of likes associated with a listing.
 * @param {object} currentListing - The current listing object.
 * @returns {array} An array of likes, or an empty array if input is invalid or likes are missing.
 */
export const getLikes = currentListing => {
  return currentListing?.attributes?.publicData?.likes || [];
};

/**
 * Returns the number of likes associated with a listing.
 * @param {object} currentListing - The current listing object.
 * @returns {number} The number of likes, or 0 if input is invalid or likes are missing.
 */
export const getLikesCount = currentListing => {
  return currentListing?.attributes?.publicData?.likesCount || 0;
};

export const isRentalListing = currentListing => {
  return currentListing?.attributes?.publicData?.isRental;
};

/**
 * Returns the shipping price or free shipping message based on the public data of a listing.
 * @param {object} publicData - The public data object of a listing.
 * @returns {string} The shipping price or free shipping message, or an empty string if input is invalid.
 */
export const getShipping = publicData => {
  const shippingPrice = publicData && publicData.shippingPriceInSubunitsOneItem;
  return publicData?.noShipping?.length
    ? FREE_SHIPPING
    : `$${shippingPrice && shippingPrice / 100} Shipping`;
};

/**
 * Returns the store name of an author.
 * @param {object} author - The author object.
 * @returns {string} The store name, or an empty string if input is invalid.
 */
export const getStoreName = author => {
  return author && author.id && author?.attributes?.profile?.publicData?.storeName;
};

/**
 * Returns the display name of an author.
 * @param {object} author - The author object.
 * @returns {string} The display name, or an empty string if input is invalid.
 */
export const getDisplayName = author => {
  const profile = author && author.id && author?.attributes?.profile;
  const isNameAccessible = profile?.firstName;
  const name = isNameAccessible ? profile?.firstName + ' ' + profile?.lastName : 'User';
  return isNameAccessible ? name : profile?.displayName;
};

/**
 * Returns the timestamp of the last activity of an author.
 * @param {object} author - The author object.
 * @returns {(number|null)} The timestamp, or null if input is invalid or lastActivity is missing.
 */
export const getLastActivity = author => {
  return author && author.id && author?.attributes?.profile?.publicData?.lastActivity;
};

/**
 * Returns the username of an author.
 * @param {object} author - The author object.
 * @returns {(string|null)} The username, or null if input is invalid or username is missing.
 */
export const getUsername = author => {
  return author && author.id && author?.attributes?.profile?.publicData?.username;
};

/**
 * Returns the bio of an author.
 * @param {object} author - The author object.
 * @returns {(string|null)} The username, or null if input is invalid or username is missing.
 */
export const getAuthorBio = author => {
  return author && author.id && author?.attributes?.profile?.bio;
};

/**
 * Returns the creation date of an author.
 * @param {object} author - The author object.
 * @returns {(Date|null)} The creation date, or null if input is invalid or date is missing.
 */
export const getCreatedAt = author => {
  return author && author.id && author?.attributes?.createdAt;
};

/**
 * Checks whether the given listing has zero stock.
 * @param {object} listing - The listing object.
 * @returns {boolean} true if the listing has zero stock, false otherwise.
 */
export const isZeroStock = listing => {
  return listing && listing.id && listing?.currentStock?.attributes?.quantity === 0;
};

/**
 * Checks whether the input is a string.
 * @param {*} input - The input value to be checked.
 * @returns {boolean} true if the input is a string, false otherwise.
 */
export const isString = input => {
  return typeof input === 'string';
};

/**
 * Returns the recently viewed listings by the current user.
 * @param {Object} currentUser - The current user object.
 * @returns {Array} An array of recently viewed listing objects.
 */
export const getRecentlyViewed = currentUser => {
  return currentUser?.attributes?.profile?.publicData?.recentlyViewed || [];
};

/**
 * Check if the user is a featured seller
 * @param {object} currentUser - The user object
 * @returns {boolean} - Whether the user is a featured seller or not
 */
export const isFeaturedSeller = currentUser => {
  return currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isFeatured;
};

/**
 * Returns the URL of the current user's profile image for listing cards.
 *
 * @param {Object} currentUser - The current user object.
 * @returns {string|null} - The URL of the user's profile image or null if not found.
 */
export const getAuthorProfileImage = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.profileImage?.attributes?.variants['listing-card-2x']?.url
  );
};

/**
 * Returns the pay in total amount of a transaction.
 * @param {Object} tx - The transaction object to retrieve the pay in total from.
 * @returns {number|undefined} The pay in total amount of the transaction, or undefined if it cannot be retrieved.
 */
export const getPayInTotal = tx => {
  return tx && tx.id && tx?.attributes?.payinTotal?.amount;
};

/**
 * Calculates the percentage of a total value.
 * @param {number} percent - The percentage value to calculate.
 * @param {number} total - The total value to calculate the percentage of.
 * @returns {number} The calculated percentage value.
 */
export const getPercentage = (percent, total) => {
  return ((percent / 100) * total).toFixed(0);
};

/**
 * Returns an array of URLs for the images of a product.
 * @param {Array} images - An array of objects representing the images of the product.
 * @returns {Array} An array of URLs of the product images.
 */
export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }

  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-large']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};

/**
 * Returns the drop time for a listing.
 * @param {Object} listing - The listing object.
 * @returns {string} The drop time string for the listing, or undefined if it doesn't exist.
 */
export const getDropTime = listing => {
  return listing?.id && listing?.attributes?.publicData?.dropTime;
};

/**
 * Returns true if current time is before the drop time of a listing.
 * @param {string} dropTime - The drop time of the listing in ISO 8601 format.
 * @returns {boolean} True if current time is before the drop time, false otherwise.
 */
export const shouldUpdateDrop = dropTime => {
  if (!dropTime) return false;
  const currentDate = new Date().getTime();
  const dropDateUnix = new Date(dropTime).getTime();
  return currentDate < dropDateUnix;
};

/**
 * Check if a listing is closed.
 *
 * @param {Object} listing - The listing object to check.
 * @returns {boolean} Whether the listing is closed or not.
 */
export const isListingClosed = listing => {
  return listing && listing.id && listing?.attributes?.state === 'closed';
};

/**
 * Checks if the current page is a search page.
 *
 * @returns {boolean} Whether or not the current page is a search page.
 */
export const isSearchPage = () => {
  // Get the current path from the window location, if available
  const currentPath = typeof window !== 'undefined' && window.location && window.location.pathname;

  // Return whether or not the current path matches the search page path
  return currentPath === '/s';
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

/**
 * Returns the label of the brand name option that matches the given key.
 * @param {string} key - The key to match against the option values.
 * @param {Array} options - The list of options to search through.
 * @returns {string|undefined} - The label of the matching option, or undefined if no match is found.
 */
export const getBrandName = (key, options) => {
  const option = options && options.find(item => item.values === key);
  return option && option.label;
};

/**
 * Checks if the user has connected their PayPal account.
 * @param {Object} currentUser - The user object.
 * @returns {boolean} - True if the user has connected their PayPal account, false otherwise.
 */
export const isPaypalConnected = currentUser => {
  return currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.merchantId;
};

/**
 * Returns whether the current user has connected their PayPal account as a seller.
 *
 * @param {object} currentUser - The current user object.
 * @returns {boolean} - Whether the user has connected their PayPal account as a seller.
 */
export const isSellerPaypalConnected = currentUser => {
  const publicData = currentUser?.attributes?.profile?.publicData;
  return publicData?.isPaypalConnected || publicData?.merchantId;
};

/**
 * Determines if the current user is using the free booking flow, meaning they are not connected to Stripe or PayPal.
 * @param {object} currentUser - The current user object.
 * @returns {boolean} - True if the current user is using the free booking flow, false otherwise.
 */
export const isFreeBookingFlow = currentUser => {
  const publicData = currentUser?.attributes?.profile?.publicData;
  return (
    (!publicData?.isStripeConnected && !publicData?.isPaypalConnected) ||
    !publicData?.isStripeConnected
  );
};

/**
 * Checks if the current user is only connected to Stripe for payment processing.
 * @param {Object} currentUser - The current user object.
 * @returns {boolean} - True if the user is only connected to Stripe, false otherwise.
 */
export const isOnlyStripeConnected = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isStripeConnected
  );
};

export const isOnlyPaypalConnected = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isPaypalConnected
  );
};

export const hasStripeConnected = currentUser => {
  const publicData = currentUser && currentUser.attributes?.profile?.publicData;
  return publicData?.isStripeConnected;
};

//This fn is used to check if stripe is connected. If not we're using TTL payout info from the env

//This fn is used to check if stripe is connected. If not we're using TTL payout info from the env
export const isStripeConnected = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.publicData?.isStripeConnected === false
  );
};

/**
 * Checks if the user has opted to not show the PayPal popup when making a payment
 * @param {Object} currentUser - The current user object
 * @returns {boolean} Returns true if the user has saved the PayPal option and has opted to not show the PayPal popup, otherwise false
 */
export const savedPaypalOption = currentUser => {
  const publicData = currentUser?.attributes?.profile?.publicData;
  return Boolean(publicData?.dontShowPaypalPopup && currentUser.id);
};

/**
 * Returns the custom Stripe account ID for the given user.
 * @param {Object} currentUser - The user object.
 * @returns {string|undefined} - The custom Stripe account ID or undefined if it's not available.
 */
export const getStripeCustomAccountId = currentUser => {
  return currentUser?.id && currentUser?.stripeAccount?.attributes?.stripeAccountId;
};

/**
 * Get the pending wallet balance of the user.
 * @param {object} currentUser - The user object.
 * @returns {number|null} - The pending wallet balance of the user or null if not available.
 */
export const getPendingWalletBalance = currentUser => {
  return currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.walletBalance != null // Check if value is not null or undefined
    ? Number(currentUser?.attributes?.profile?.protectedData?.walletBalance) // Convert to number
    : null;
};

/**
 * Checks if the email verification banner should be hidden for the current user.
 *
 * @param {Object} currentUser - The current user object.
 * @param {string} currentUser.id - The ID of the current user.
 * @param {Object} currentUser.attributes - The attributes of the current user.
 * @param {Object} currentUser.attributes.profile - The profile attributes of the current user.
 * @param {Object} currentUser.attributes.profile.protectedData - The protected data of the current user's profile.
 * @param {boolean} currentUser.attributes.profile.protectedData.hideEmailVerificationBanner - Whether the email verification banner should be hidden.
 * @returns {boolean} Whether the email verification banner should be hidden.
 */
export const hideEmailVerificationBanner = currentUser => {
  const shouldHide =
    currentUser &&
    currentUser.id &&
    currentUser.attributes?.profile?.protectedData?.hideEmailVerificationBanner;
  return !!shouldHide; // Ensure that the function always returns a boolean value
};

/**
 * Get the shipping preference of the current user
 * @param {Object} currentUser - The current user object
 * @returns {string} - The shipping preference of the current user
 */
export const getOwnShippingPreference = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.shippingPreference
  );
};

export const getAuthorShippingPreference = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.publicData?.shippingPreference
  );
};

/**
 * Returns the shipping details for the current user.
 * @param {object} currentUser - The current user object.
 * @returns {object|null} - The shipping details object if found, otherwise null.
 * @throws {Error} - If the currentUser object is not valid.
 */
export const getOwnShippingDetails = currentUser => {
  const shippingDetails = currentUser?.attributes?.profile?.protectedData?.shippingDetails;
  return shippingDetails || null;
};

/**
 * Returns the email of a user object, if it exists.
 * @param {Object} user - The user object to retrieve the email from.
 * @returns {string|undefined} - The email of the user, or undefined if it doesn't exist.
 */
export const getEmail = user => {
  if (!user || !user.id || !user.attributes) {
    return undefined;
  }
  return user.attributes.email;
};

/**
 * Returns the phone number of a user.
 * @param {Object} user - The user object.
 * @param {string} user.id - The ID of the user.
 * @param {Object} user.attributes - The attributes of the user.
 * @param {Object} user.attributes.profile - The profile information of the user.
 * @param {Object} user.attributes.profile.protectedData - The protected data of the user's profile.
 * @param {string} user.attributes.profile.protectedData.phoneNumber - The phone number of the user.
 * @returns {string} The phone number of the user, or null if the user is not provided or does not have a phone number.
 */
export const getPhoneNumber = user => {
  return (user?.id && user?.attributes?.profile?.protectedData?.phoneNumber) || null;
};

/**
 * Returns the shipping address of the given user, if it exists.
 * @param {Object} user - The user object to get the shipping address from.
 * @returns {Object|undefined} - The shipping address object or undefined if it does not exist.
 */
export const getShippingAddress = user => {
  const protectedData = user && user.attributes?.profile?.protectedData;
  return protectedData?.shippingAddress;
};

/**
 * Returns the Shippo address of the given user.
 *
 * @param {object} user - The user object to extract the address from.
 * @returns {string|null} The Shippo address of the user or null if it doesn't exist.
 */
export const getShippoAddress = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.shippoAddress;
};

/**
 * Returns the address object ID of the user if it exists.
 *
 * @param {Object} user - The user object.
 * @returns {string|undefined} - The address object ID of the user if it exists, otherwise undefined.
 */
export const getAddressObjectId = user => {
  return user?.attributes?.profile?.publicData?.addressObjectId;
};

export const getDefaultDimensions = user => {
  return user?.attributes?.profile?.publicData?.defaultDimensions;
};

/**
 * Returns the shipping address metadata of the transaction.
 * @param {Object} tx - The transaction object.
 * @returns {Object|undefined} - The shipping address metadata of the transaction if it exists, otherwise undefined.
 */
export const getTxShippingData = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shippingAddress;
};

/**
 * Checks if a transaction is a shipping transaction.
 *
 * @param {Object} tx - The transaction object.
 * @returns {boolean} - True if the transaction is a shipping transaction, otherwise false.
 */
export const isShipping = tx => {
  return (
    tx &&
    tx.id &&
    tx.attributes &&
    tx.attributes.metadata &&
    tx.attributes.metadata.delivery === SHIPPING
  );
};

/**
 * Returns the shipping rate of a transaction.
 * @param {Object} tx - The transaction object.
 * @returns {string|undefined} - The shipping rate of the transaction if it exists, otherwise undefined.
 */
export const getTxShippingRate = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shippingAddress?.rate;
};

/**
 * Checks whether the given transaction has a valid shipment object.
 *
 * @param {Object} tx - The transaction object.
 * @returns {boolean} - True if the transaction has a valid shipment object, false otherwise.
 */
export const isValidShipmentTx = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shipment?.object_id;
};

export const isCustomShippingFlow = tx => {
  return tx && tx.id && !!tx?.listing?.attributes?.publicData?.shippingPrice;
};

/**
 * Returns the shipment metadata of a transaction, if it exists.
 *
 * @param {Object} tx - The transaction object to retrieve shipment metadata from.
 *
 * @returns {Object|null} - Returns the shipment metadata object if it exists, or `null` if it does not.
 */
export const getTxShipment = tx =>
  tx && tx.id && tx.attributes?.metadata?.shipment ? tx.attributes.metadata.shipment : null;

/**
 * Returns the buyer information associated with a transaction, if it exists.
 *
 * @param {Object} tx - The transaction object to retrieve buyer information from.
 *
 * @returns {Object|null} - Returns the buyer information object if it exists, or `null` if it does not.
 */
export const getBuyerInfo = tx =>
  tx && tx.id && tx.attributes?.protectedData?.buyerInfo
    ? tx.attributes.protectedData.buyerInfo
    : null;

/**
 * Returns the tracking details associated with a transaction, if they exist.
 *
 * @param {Object} tx - The transaction object to retrieve tracking details from.
 *
 * @returns {Object} - Returns an object containing the tracking details, or an empty object if no tracking details are found.
 */
export const getTrackingDetails = tx => {
  const trackingInfo = tx && tx.id && tx?.attributes?.metadata?.trackingInfo;
  const carrierName =
    tx && tx.id && tx?.attributes?.protectedData?.shippingDetails?.moreDetails?.shippingInfo?.name;
  return {
    trackingNumber: trackingInfo?.tracking_number || null,
    trackingUrlProvider: trackingInfo?.tracking_url_provider || null,
    trackingLabel: trackingInfo?.label_url || null,
    carrierName: trackingInfo?.rate?.provider || carrierName || null,
    rate: trackingInfo?.rate || null,
    qrCode: trackingInfo?.qr_code_url || null,
  };
};

export const getReturnParams = t => {
  return t && t.id && t?.attributes?.metadata?.returnParams;
};

export const getReturnTrackingInfo = t => {
  return t && t.id && t?.attributes?.metadata?.returnTrackingInfo;
};

/**
 * Returns the extended shipping details associated with a transaction, if they exist.
 *
 * @param {Object} tx - The transaction object to retrieve extended shipping details from.
 *
 * @returns {Object|null} - Returns the extended shipping details object if it exists, or `null` if it does not.
 */
export const getShippingExtendedDetails = tx =>
  (tx && tx.id && tx?.attributes?.protectedData?.shippingDetails?.moreDetails?.shippingInfo) ||
  null;

/**
 * Returns the seller's shipping preference, if it exists.
 *
 * @param {Object} user - The user object to retrieve shipping preferences from.
 *
 * @returns {string|null} - Returns the seller's shipping preference string if it exists, or `null` if it does not.
 */
export const getSellerShippingPreference = user =>
  (user && user.id && user?.attributes?.profile?.publicData?.shippingType) || null;

export const getSellerThresholdAmount = user => {
  const amount = user && user.id && user?.attributes?.profile?.publicData?.thresholdAmount;
  return amount;
};

/**
 * Returns the saved shipping dimensions associated with a user, if they exist.
 *
 * @param {Object} user - The user object to retrieve shipping dimensions from.
 *
 * @returns {Object} - Returns an object containing the shipping dimensions, or an object with all properties set to `null` if no shipping dimensions are found.
 */
export const getSavedDimensions = user => {
  const preferences =
    user && user.id && user?.attributes?.profile?.protectedData?.otherShippingPreferences;
  return {
    weight: preferences?.weight || null,
    height: preferences?.height || null,
    width: preferences?.width || null,
    length: preferences?.length || null,
  };
};

export const checkWhoWillPayShipping = (user, totalOrderValue) => {
  const { shippingType, shippingPreference, thresholdAmount } =
    user?.attributes?.profile?.publicData || {};

  if (shippingPreference === 'own') return { isOwnShipping: true };
  if (shippingPreference === TUTULIST_LOWERCASE && shippingType === 'free')
    return { payer: 'both', showCheapest: true };
  if (shippingPreference === TUTULIST_LOWERCASE && shippingType === 'shipping') {
    if (!thresholdAmount) return { payer: 'buyer' };
    if (thresholdAmount >= totalOrderValue) return { payer: 'buyer' };
    return { payer: 'both', showCheapest: true };
  }
};

/**
 * Retrieves the shipping information from a transaction's protected data.
 * @param {Object} tx - The transaction object to retrieve the shipping information from.
 * @param {string} tx.id - The ID of the transaction.
 * @param {Object} tx.attributes - The attributes object of the transaction.
 * @returns {Object} - The shipping information object, if found. Otherwise, undefined.
 */
export const getTransactionShippoInfo = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.shippingDetails?.moreDetails?.shippingInfo;
};

/**
 * Checks if a transaction is a shipping transaction.
 *
 * @param {Object} tx - The transaction object.
 * @returns {number} - The offer price of the transaction
 */
export const getOfferPrice = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams?.offerPrice;
};

export const getOfferParams = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams;
};

export const isOwnOffer = (customer, currentUser) => {
  return customer?.id?.uuid === currentUser?.id?.uuid;
};

export const getSimilarParams = (listing, currentUser) => {
  const publicData = listing?.attributes?.publicData;
  return {
    brand: publicData?.brand,
    size: publicData?.size,
  };
};

export const getPricingDetails = listing => {
  const publicData = listing?.attributes?.publicData;
  return publicData?.pricing;
};

//This is a sharetribe response import with data.data
export const getPublicDataAttributeValue = (attributeName, data) => {
  if (data && data.attributes && data.attributes.publicData) {
    const publicData = data.attributes.publicData;
    if (publicData.hasOwnProperty(attributeName)) {
      return publicData[attributeName];
    }
  }
  return false;
};

export const getUserSoldCount = user => {
  return (user && user.id && user?.attributes?.profile?.publicData?.soldCount) || 0;
};

export const getUserOfferPrices = user => {
  return (user && user.id && user?.attributes?.profile?.privateData?.offerPrices) || [];
};

export const getVacationMode = user => {
  return (user && user.id && user?.attributes?.profile?.privateData?.vacationMode) || false;
};

export const isOfferProcess = tx => {
  return tx?.attributes?.processName === OFFER_PROCESS;
};

export const getUserEarnings = user => {
  return (user && user.id && user?.attributes?.profile?.protectedData?.totalEarnings) || 0;
};

export const getGTMParams = (listing, user) => {
  const id = listing?.id?.uuid;
  const title = listing?.attributes?.title;
  const price = listing?.attributes?.price;
  const publicData = listing?.attributes?.publicData;
  const productPrice = price && price.amount / 100;
  const bookmarks = getBookmarks(user);

  const tagParams = {
    item_id: id,
    item_name: title,
    price: productPrice,
    quantity: 1,
    index: (bookmarks?.length || 0) + 1,
    item_brand: publicData?.brand,
    item_category: publicData?.category,
    item_category2: publicData?.condition,
    item_category3: publicData?.color,
    item_category4: publicData?.size,
    item_variant: publicData?.sku || '',
  };

  return tagParams;
};

export const getImageURLSFromImages = images => {
  const imagesURLS = isArrayLength(images) ? images?.map(i => i?.id?.uuid) : null;
  return imagesURLS;
};

export const extractVariantTitle = title => {
  // Check if the title is null or undefined
  if (title == null) {
    return '';
  }

  // Validate if the title is a string
  if (typeof title !== 'string') {
    return '';
  }

  // Check if the string is empty
  if (title.length === 0) {
    return '';
  }

  // Split the title by the hyphen
  const parts = title.split('-');

  // Return the first part of the split array
  return parts[0];
};

export const getStoreNameOrDisplayName = author => {
  const storeName = getStoreName(author);
  const displayName = getDisplayName(author);
  return storeName || displayName;
};

export const isRentalSearchPage = () =>
  typeof window !== 'undefined' && window?.location?.pathname === IS_COSTUMES_RENTALS;

export const isValidListings = listings => {
  return isArrayLength(listings)
    ? listings?.filter(
        l =>
          !l?.attributes?.publicData?.vacationMode &&
          !l?.attributes?.publicData?.parentId &&
          !l?.attributes?.publicData?.privateListing
      )
    : [];
};

export const getChildListings = listings => {
  return isArrayLength(listings) ? listings?.filter(l => l?.attributes?.publicData?.parentId) : [];
};

/**
 * Filters, sorts, and ensures uniqueness of listings based on specific criteria.
 *
 * @param {Array} listings - The array of listing objects to process.
 * @returns {Array} - The processed array of listings.
 */
export const getValidChildListings = listings => {
  if (!isArrayLength(listings)) return [];

  return listings
    .sort((a, b) => {
      const aMatch = a?.id?.uuid === a?.attributes?.publicData?.parentId;
      const bMatch = b?.id?.uuid === b?.attributes?.publicData?.parentId;
      return bMatch - aMatch; // Prioritize listings where id matches parentId
    })
    .filter((listing, index, self) => {
      const parentId = listing?.attributes?.publicData?.parentId;
      // Always include listings without a parentId
      if (!parentId) return true;

      // Always include if listing's id matches its parentId
      if (listing?.id?.uuid === parentId) return true;

      // Include if it's the first occurrence of this parentId in the sorted array
      return index === self.findIndex(item => item?.attributes?.publicData?.parentId === parentId);
    });
};

/**
 * Sorts an array of listings by their createdAt attribute.
 * @param {Array} listings - The array of listings to sort.
 * @param {boolean} [ascending=true] - Whether to sort in ascending order. Default is true.
 * @returns {Array} - The sorted array of listings.
 */
export const sortListingsByCreatedAt = (listings, ascending = false) => {
  // Validate input: check if it's an array and not empty
  if (!isArrayLength(listings)) {
    return []; // Return the original input if validation fails
  }

  // Proceed with sorting if validation is successful
  return listings.slice().sort((a, b) => {
    const aCreatedAt = moment(a?.attributes?.createdAt);
    const bCreatedAt = moment(b?.attributes?.createdAt);
    // Sort based on the ascending parameter
    return ascending ? aCreatedAt.diff(bCreatedAt) : bCreatedAt.diff(aCreatedAt);
  });
};

/**
 * Combines and sorts page listings and child listings, ensuring only valid and unique listings are included.
 *
 * @param {Array} pageListings - The array of original page listings.
 * @returns {Array} - A sorted and filtered array of listings.
 */
export const combineAndSortListings = pageListings => {
  // Get child listings from the page listings
  const childListings = getChildListings(pageListings);

  // Get valid child listings after applying specific filters and checks
  const sortedAndUniqueListings = getValidChildListings(childListings);

  // Combine valid page listings with sorted and unique child listings, then sort them by createdAt
  const onlyValidListings = sortListingsByCreatedAt(
    [...isValidListings(pageListings), ...sortedAndUniqueListings],
    false
  ); // Assuming false is your desired default for sorting order (descending)

  return onlyValidListings;
};

export const getParentId = l => {
  return l && l.id && l?.attributes?.publicData?.parentId;
};
export const getSizes = l => {
  return l && l.id && l?.attributes?.publicData?.sizes;
};

export const createValidVariantsParams = listings => {
  const variants = isArrayLength(listings)
    ? listings.map(listing => {
        const publicData = listing?.attributes?.publicData;
        const currentStock = listing.currentStock?.attributes?.quantity || 0;
        const imagesIds = isArrayLength(listing?.images) && listing?.images?.map(i => i?.id?.uuid);
        return {
          id: listing?.id?.uuid,
          imagesIds,
          size: publicData?.sizeOption,
          sku: publicData?.sku,
          stock: currentStock,
        };
      })
    : [];
  return variants;
};
