import { ACCESSORIES, CATEGORY, COSTUMES, PRACTICE_WEAR, SKATES } from '../constants';
import { EXTENDED_DATA_SCHEMA_TYPES } from './types';
import { types as sdkTypes } from '../util/sdkLoader';
import { isArrayLength } from './genericHelpers';
const { Money } = sdkTypes;

export function getConfigData(config, selectedCategory) {
  // Ensure config.listing is defined and not null before destructuring
  if (!config?.listing) {
    return {}; // Return empty object if listing is missing
  }

  const descriptions = {
    costumes: 'List new and gently used dresses, costumes, and performance wear.',
    'skates-blades': 'List new and gently used boots and blades.',
    'practice-wear': 'List new and gently used practice wear, leggings, tops, skirts, etc.',
    accessories: 'List new and gently used leg warmers, bags, guards, hair pieces, etc.',
  };

  const { listingFields = [] } = config.listing; // Destructure with default
  const categoryConfig =
    isArrayLength(listingFields) && listingFields.filter(field => field.key === CATEGORY);

  const listingCategories = listingFields.reduce((acc, item) => {
    if (item?.key === CATEGORY) {
      acc = item.enumOptions.map(item => ({
        option: item.option,
        label: item.label,
        description: descriptions[item.option] || '', // Assign description if available, otherwise empty string
      }));
    }
    return acc;
  }, []);

  // Initialize groupedFields object
  const groupedFields = {
    costume: [],
    practice: [],
    accessories: [],
    skates: [],
  };

  // Use a map function for cleaner category filtering
  const categorizedFields = listingFields
    .map(item => {
      const category = item.key.split('_')[0]; // Extract category directly

      return { ...item, category }; // Spread existing item properties and add category
    })
    .filter(item => item !== null); // Filter out null items here;

  categorizedFields.length &&
    categorizedFields.reduce((acc, item) => {
      if (item) {
        // Only process items that weren't skipped in map
        acc[item.category]?.push(item);
      }
      return acc;
    }, groupedFields);

  let listingFieldsConfig;

  // Handle the case when selectedCategory is an array
  if (Array.isArray(selectedCategory)) {
    // Combine listing fields based on multiple categories
    listingFieldsConfig = selectedCategory.reduce((acc, category) => {
      switch (category) {
        case COSTUMES:
          acc.push(
            ...groupedFields.costume,
            ...getCommonFields(['listing_type', 'size', 'condition', 'color'], listingFields)
          );
          break;
        case SKATES:
          acc.push(...groupedFields.skates);
          break;
        case PRACTICE_WEAR:
          acc.push(
            ...groupedFields.practice
            // ...getCommonFields(['size', 'condition', 'color', 'listing_type'], listingFields)
          );
          break;
        case ACCESSORIES:
          acc.push(
            ...groupedFields.accessories
            // ...getCommonFields(['condition', 'color', 'listing_type'], listingFields)
          );
          break;
        default:
          break;
      }
      return acc;
    }, []);
  } else {
    // Handle the case when selectedCategory is not an array
    switch (selectedCategory) {
      case COSTUMES:
        listingFieldsConfig = [
          ...groupedFields.costume,
          ...getCommonFields(['listing_type', 'size', 'condition', 'color'], listingFields),
        ];
        break;
      case SKATES:
        listingFieldsConfig = [...groupedFields.skates];
        break;
      case PRACTICE_WEAR:
        listingFieldsConfig = [
          ...groupedFields.practice,
          ...getCommonFields(['size', 'condition', 'color', 'listing_type'], listingFields),
        ];
        break;
      case ACCESSORIES:
        listingFieldsConfig = [
          ...groupedFields.accessories,
          ...getCommonFields(['condition', 'color', 'listing_type'], listingFields),
        ];
        break;
      default:
        listingFieldsConfig = listingFields;
        break;
    }
  }

  return {
    listingFieldsConfig,
    listingCategories,
    categoryConfig,
  };
}

// Helper function to fetch common fields
function getCommonFields(fieldNames, listingFields) {
  return fieldNames.map(fieldName => listingFields.find(item => item.key === fieldName));
}

/**
 * Check if listingType has already been set.
 *
 * If listing type (incl. process & unitType) has been set, we won't allow change to it.
 * It's possible to make it editable, but it becomes somewhat complex to modify following panels,
 * for the different process. (E.g. adjusting stock vs booking availability settings,
 * if process has been changed for existing listing.)
 *
 * @param {Object} publicData JSON-like data stored to listing entity.
 * @returns object literal with to keys: { hasExistingListingType, existingListingTypeInfo }
 */
export function hasSetListingType(publicData) {
  const { listingType, transactionProcessAlias, unitType } = publicData;
  const existingListingTypeInfo = { listingType, transactionProcessAlias, unitType };

  return {
    hasExistingListingType: !!listingType && !!transactionProcessAlias && !!unitType,
    existingListingTypeInfo,
  };
}

/**
 * Pick extended data fields from given extended data of the listing entity.
 * Picking is based on extended data configuration for the listing and target scope and listing type.
 *
 * This returns namespaced (e.g. 'pub_') initial values for the form.
 *
 * @param {Object} data extended data values to look through against listingConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetListingType Check that the extended data is relevant for this listing type.
 * @param {Object} listingFieldConfigs an extended data configurtions for listing fields.
 * @returns Array of picked extended data fields
 */
function initialValuesForListingFields(data, targetScope, targetListingType, listingFieldConfigs) {
  return listingFieldConfigs.reduce((fields, field) => {
    const { key, includeForListingTypes, scope = 'public', schemaType } = field || {};
    const namespacePrefix = scope === 'public' ? `pub_` : `priv_`;
    const namespacedKey = `${namespacePrefix}${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(targetListingType);

    if (isKnownSchemaType && isTargetScope && isTargetListingType) {
      const fieldValue = data[key] || null;
      return { ...fields, [namespacedKey]: fieldValue };
    }
    return fields;
  }, {});
}

/**
 * Get listing configuration. For existing listings, it is stored to publicData.
 * For new listings, the data needs to be figured out from listingTypes configuration.
 *
 * In the latter case, we select first type in the array. However, EditListingDetailsForm component
 * gets 'selectableListingTypes' prop, which it uses to provide a way to make selection,
 * if multiple listing types are available.
 *
 * @param {Array} listingTypes
 * @param {Object} existingListingTypeInfo
 * @returns an object containing mainly information that can be stored to publicData.
 */
export function getTransactionInfo(
  listingTypes,
  existingListingTypeInfo = {},
  inlcudeLabel = false
) {
  const { listingType, transactionProcessAlias, unitType } = existingListingTypeInfo;

  if (listingType && transactionProcessAlias && unitType) {
    return { listingType, transactionProcessAlias, unitType };
  } else if (listingTypes.length === 1) {
    const { listingType: type, label, transactionType } = listingTypes[0];
    const { alias, unitType: configUnitType } = transactionType;
    const labelMaybe = inlcudeLabel ? { label: label || type } : {};
    return {
      listingType: type,
      transactionProcessAlias: alias,
      unitType: configUnitType,
      ...labelMaybe,
    };
  }
  return {};
}

/**
 * Get initialValues for the form. This function includes
 * title, description, listingType, transactionProcessAlias, unitType,
 * and those publicData & privateData fields that are configured through
 * config.listing.listingFields.
 *
 * @param {object} props
 * @param {object} existingListingTypeInfo info saved to listing's publicData
 * @param {object} listingTypes app's configured types (presets for listings)
 * @param {object} listingFieldsConfig those extended data fields that are part of configurations
 * @returns initialValues object for the form
 */
export function getInitialValues({
  props,
  existingListingTypeInfo,
  listingTypes,
  listingFieldsConfig,
  currency,
}) {
  const { description, title, publicData, privateData, price } = props?.listing?.attributes || {};
  const {
    listingType,
    shippingPriceInSubunitsOneItem,
    pickupEnabled,
    size,
    location,
    tags,
    shippingEnabled,
    shippingPriceInSubunitsAdditionalItems,
    building,
    deliveryOptions,
    freeShipping,
    weight,
    massUnit,
  } = publicData;

  // Initial values for the form
  return {
    title,
    description,
    pickupEnabled,
    price,
    location,
    size,
    tags,
    shippingEnabled,
    pickupEnabled,
    shippingPriceInSubunitsAdditionalItems: new Money(
      shippingPriceInSubunitsAdditionalItems ?? 0,
      currency
    ),
    building,
    weight,
    deliveryOptions,
    massUnit,
    freeShipping: freeShipping ? ['true'] : null,
    stock: props?.listing?.currentStock?.attributes?.quantity,
    shippingPriceInSubunitsOneItem: new Money(shippingPriceInSubunitsOneItem ?? 0, currency),
    // Transaction type info: listingType, transactionProcessAlias, unitType
    ...getTransactionInfo(listingTypes, existingListingTypeInfo),
    ...initialValuesForListingFields(publicData, 'public', listingType, listingFieldsConfig),
    ...initialValuesForListingFields(privateData, 'private', listingType, listingFieldsConfig),
  };
}

/**
 * Pick extended data fields from given form data.
 * Picking is based on extended data configuration for the listing and target scope and listing type.
 *
 * This expects submit data to be namespaced (e.g. 'pub_') and it returns the field without that namespace.
 * This function is used when form submit values are restructured for the actual API endpoint.
 *
 * Note: This returns null for those fields that are managed by configuration, but don't match target listing type.
 *       These might exists if provider swaps between listing types before saving the draft listing.
 *
 * @param {Object} data values to look through against listingConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetListingType Check that the extended data is relevant for this listing type.
 * @param {Object} listingFieldConfigs an extended data configurtions for listing fields.
 * @returns Array of picked extended data fields from submitted data.
 */

export function pickListingFieldsData(data, targetScope, targetListingType, listingFieldConfigs) {
  return listingFieldConfigs.reduce((fields, field) => {
    const { key, includeForListingTypes, scope = 'public', schemaType } = field || {};
    const namespacePrefix = scope === 'public' ? `pub_` : `priv_`;
    const namespacedKey = `${namespacePrefix}${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(targetListingType);

    if (isKnownSchemaType && isTargetScope && isTargetListingType) {
      const fieldValue = data[namespacedKey] || null;
      return { ...fields, [key]: fieldValue };
    } else if (isKnownSchemaType && isTargetScope && !isTargetListingType) {
      // Note: this clears extra custom fields
      // These might exist if the provider swaps between listing types before saving the draft listing.
      return { ...fields, [key]: null };
    }
    return fields;
  }, {});
}

export function getListingDetails(listing) {
  if (!listing?.id?.uuid) return null;

  const { publicData = {} } = listing?.attributes || {};
  const { category = '' } = publicData || {};
  return {
    category,
  };
}

export function getUserDetails(user) {
  // Combine first and last names using optional chaining and nullish coalescing
  const fullName = user?.attributes?.profile?.firstName
    ? `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`
    : user?.attributes?.profile?.displayName;

  // Use optional chaining and nullish coalescing for profile image
  const profileImage =
    user?.profileImage?.attributes?.variants?.default?.url ||
    user?.profileImage?.attributes?.variants?.['square-small2x']?.url ||
    null; // Set to null if no image variants found

  // Use optional chaining and nullish coalescing for email
  const email = user?.attributes?.email;

  // Extract ID using optional chaining and nullish coalescing
  const id = user?.id?.uuid;
  const firstName = user?.attributes?.profile?.firstName;
  const { stripeConnected } = user?.attributes || {};

  // Return object using destructuring assignment and computed property for clarity
  return {
    fullName,
    profileImage,
    email,
    id,
    firstName,
    stripeConnected,
  };
}
export function getShippingPreference(user) {
  // Consolidated null/undefined checks for robustness:
  if (!user || !user.id) {
    // Handle missing user or user ID gracefully
    return null;
  }

  // Combine optional chaining with default value for concise and safe access:
  const protectedData = user.attributes?.profile?.protectedData || {};
  return protectedData.shippingPreference ?? null;
}

export function isOwnShippingPreference(user) {
  return user?.attributes?.profile?.publicData?.shippingPreference == 'own';
}

export function getShippingAddressDetails(user) {
  // Consolidated null/undefined checks for robustness:
  if (!user || !user.id) {
    // Handle missing user or user ID gracefully
    return null;
  }

  // Combine optional chaining with default value for concise and safe access:
  const protectedData = user.attributes?.profile?.protectedData || {};
  return protectedData.shippingAddress ?? null;
}

export function getUserCartDetails(user) {
  // Consolidated null/undefined checks for robustness:
  if (!user || !user.id) {
    // Handle missing user or user ID gracefully
    return null;
  }

  // Combine optional chaining with default value for concise and safe access:
  const protectedData = user.attributes?.profile?.protectedData || {};
  return protectedData.cartItems ?? [];
}

export const getUserShippingAddress = user => {
  if (!user && !user?.id) return null;
  const { shippingAddress = {} } = user?.attributes?.profile?.protectedData || {};
  return shippingAddress;
};

export const getAddressObjectId = user => {
  if (!user && !user?.id) return null;

  return user?.attributes?.profile?.publicData?.addressObjectId ?? null;
};

export const getPayInTotal = tx => {
  return tx?.attributes?.payinTotal?.amount ?? 0;
};
export const getDefaultDimensions = user => {
  return user?.attributes?.profile?.publicData?.packageDimensions ?? {};
};

export const getShopData = user => {
  const { returnPolicy, shopname } = user?.attributes?.profile?.publicData || {};
  return {
    returnPolicy,
    shopname,
  };
};

export const calculateAverageRating = reviews => {
  // Filter out deleted reviews
  const validReviews = reviews.filter(review => !review.attributes.deleted);

  // Calculate the total rating and the number of reviews
  const totalRating = validReviews.reduce((sum, review) => sum + review.attributes.rating, 0);
  const totalReviews = validReviews.length;

  // Calculate the average rating
  const averageRating = totalReviews > 0 ? totalRating / totalReviews : 0;

  return {
    averageRating: Number(averageRating.toFixed(1)), // rounding to 2 decimal places
    totalReviews: `${totalReviews} reviews`,
  };
};

export const isTxFreeShipping = tx => {
  const protectedData = tx && tx.id && tx?.attributes?.protectedData;
  return protectedData?.isFreeShipping;
};

export const getDestinationAddress = tx => {
  const metaData = tx && tx.id && tx?.attributes?.metadata;
  const protectedData = tx && tx.id && tx?.attributes?.protectedData;
  const trackingInfo = metaData?.destinationAddress || protectedData?.destinationAddress;
  return trackingInfo;
};

/**
 * Returns the tracking details associated with a transaction, if they exist.
 *
 * @param {Object} tx - The transaction object to retrieve tracking details from.
 *
 * @returns {Object} - Returns an object containing the tracking details, or an empty object if no tracking details are found.
 */

export const getTrackingDetails = (tx, isReturn = false) => {
  const metadata = tx && tx.id && tx?.attributes?.metadata;
  const trackingInfo = isReturn ? metadata?.returnTrackingInfo : metadata?.trackingInfo;
  const carrierName =
    tx && tx.id && tx?.attributes?.protectedData?.shippingDetails?.moreDetails?.shippingInfo?.name;
  return {
    ...trackingInfo,
    trackingNumber: trackingInfo?.tracking_number || null,
    trackingUrlProvider: trackingInfo?.tracking_url_provider || null,
    trackingLabel: trackingInfo?.label_url || null,
    carrierName: trackingInfo?.rate?.provider || carrierName || null,
    rate: trackingInfo?.rate || null,
  };
};
